<template>
  <div>
    <!-- page header component -->
    <page-header name="add new user">
      <template #icon>
        <b-icon
          icon="users"
          icon-pack="fas"
        />
      </template>
    </page-header>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-three-fifths">
            <!-- loading component -->
            <b-loading
              :is-full-page="false"
              v-model="isLoading"
              :can-cancel="false"
            />

            <!-- error message component -->
            <b-message
              v-model="isInvalid"
              type="is-warning"
              has-icon
            >
              <strong>{{ message }}</strong>
            </b-message>

            <!-- users form component -->
            <users-form @save-user="createUser" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { PageHeader } from '@/components/Shared';
import { UsersForm } from '@/components/Users';

import UsersMixin from '@/mixins/UsersMixin';

export default {

  name: 'AddUser',

  components: {
    PageHeader,
    UsersForm,
  },

  mixins: [UsersMixin],

  methods: {
    async createUser() {
      try {
        this.isInvalid = false;
        await this.$store.dispatch('Users/createUser', this.user);
        this.$router.push('/users');
      } catch (error) {
        this.isInvalid = true;
        this.message = error.message;
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
